import React, {useEffect} from 'react'
import Router from 'next/router'

const Page404 = (props: any) => {
  useEffect(() => {
    Router.replace('/')
    return () => {}
  }, [])
  return <div />
}
export default Page404
